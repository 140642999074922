/* Card Styling */
.product-card {
  width: 90%; /* Adjust the card width */
  background: transparent;
  border: none;
  margin-bottom: -7rem;
  margin-top: -50px;
}

.product-image {
  height: 200px; /* Adjust based on your design */
  object-fit: contain; /* Keeps the image aspect ratio */
}

.product-title {
  font-size: 1.3rem;
  font-weight: 400;
  color: #333;
  text-align: center;
}

.product-brand {
  font-size: 0.9rem;
  color: #888;
}

.card-body {
  text-align: center; /* Center text */
}
@media (max-width: 1280.99px) {
  .product-card {
    width: 90%; /* Adjust the card width */
    height: 50%;
  }
  .product-title {
    font-size: 1rem;
    text-align: left;
  }
}
@media (max-width: 770px) {
  .product-card {
    margin-bottom: -7rem;
    height: 50%;
    margin-top: -50px;
  }
  .hide-on-small-screens{
    display: none !important;
  }

  .product-title {
    font-size: 1rem;
    text-align: center !important;
  }
  .product-image {
    width: 160px !important;
  }
}
@media (max-width: 576px) {
  .product-image {
    height: 150px;
  }
  .product-card {
    height: 350px;
  }
  .product-title {
    font-size: 1rem;
  }
}
