/* Override default Bootstrap carousel controls */
.carousel-control-prev,
.carousel-control-next {
  width: 10vw; /* Increase the size of the hover area */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black; /* Background color (changed to green in this case) */
  width: 5rem; /* Increase the container size */
  height: 5rem; /* Increase the container size */
}

.carousel-control-prev-icon::before,
.carousel-control-next-icon::before {
  color: white; /* White arrow */
  font-size: 0.5rem; /* Larger arrow size */
}

/* Positioning the arrows */
.carousel-control-prev {
  left: 20px; /* Keep it far left */
}

.carousel-control-next {
  right: 20px; /* Keep it far right */
}
.carousel-caption {
  position: absolute;
  top: 50%;
  left: 10%; /* Adjust for smaller screens */
  transform: translateY(-50%);
  text-align: left;
  max-width: 90%; /* Allow the caption to take up more width on mobile */
}

@media (min-width: 768px) {
  .carousel-caption {
    left: 60%;
    max-width: 40%; /* Keep this layout for larger screens */
  }
}

.last-section-text {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.read-more-btn {
  background-color: white;
  color: #3a3a3c;
  border: none;
  transition: background-color 0.3s ease;
}

.read-more-btn:hover {
  background-color: #3a3a3c !important;
}

.read-more-btn2 {
  background-color: #B22222;
  color: #fff;
  border: none;
  transition: background-color 0.3s ease;
  /* padding: 10px 3px 10 3px;
  font-size: 30px;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0px; */
}

.read-more-btn2:hover {
  background-color: #3a3a3c !important;
}

/* Third section */
/* Parallax Section */
.parallax-section {
  /* height: 50vh; Full viewport height */
  background-image: url("crates.jpg"); /* Set your background image */
  background-attachment: fixed; /* Parallax effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Cover the entire area */
  display: flex;
  align-items: center; /* Center content vertically */
  opacity: 0.9;
}

/* Text Content */
.content-area {
  padding: 70px; /* Add some padding to the text */
  background-color: transparent; /* Add a slight background to make the text readable */
  margin-left: 20px; /* Optional: Add margin to the left */
  justify-content: flex-start;
  padding-right: 150px;
}

/* For screens larger than 992px (large screens), set a max-width */
@media (min-width: 992px) {
  .content-area {
    max-width: 100%; /* Fill the entire column defined by the Bootstrap grid */
  }
}

.content-area h2 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 3rem;
}

.content-area p {
  font-size: 16px;
  line-height: 2;
}

/* fourth section */
.info-section {
  background-color: #f9f9f9;
}

.info-section h2 {
  font-size: 36px;
  color: #000;
  margin-bottom: 20px;
  font-weight: 700;
}

.info-section .highlight-text {
  color: #B22222; /* Highlighted red text */
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 20px;
}

.info-section p {
  color: #333;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 20px;
}
.under-highlight-text {
  font-size: 12px !important;
  line-height: 1.3rem !important;
  margin-bottom: 20px !important;
  font-weight: 350 !important;
}
.read-more-btn3 {
  background-color: #B22222;
  color: white;
  padding: 10px 30px;
  font-weight: bold;
  border: none;
  border-radius: 0px;
  text-transform: uppercase;
  transition: background-color 0.3s;
}

.read-more-btn3:hover {
  background-color: #3a3a3c !important;
  color: white !important;
}

/* Fifth Section */
/* last section  */

.service-box {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}

.icon-box {
  width: 50px;
  height: 50px;
  background-color: #e40000; /* Red background */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
}

.icon-img {
  width: 60px ; /* Icon size */
  height: auto;
}

.service-text {
  color: #fff;
  margin-left: 15px; /* Padding between icon and text */
}

.truck-img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

@media (max-width: 992px) {
  /* Stack the columns on smaller screens */
  .trucking-services-section {
    display: block;
  }

  .service-box {
    justify-content: flex-start;
  }

  .truck-img {
    margin-top: 20px; /* Add margin when image goes below services */
  }
}

/* Icon and Text Row */
.icon-box {
  width: 120px; /* Set a fixed size for the icon container */
  height: 76px;
  background-color: #B22222; /* Red background color */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
}

.icon {
  width: 50px; /* Ensure the icon scales properly within the box */
  height: auto;
}

.service-row {
  display: flex;
  align-items: center; /* Ensures icons and text are aligned vertically */
  justify-content: center; /* Aligns the content horizontally */
  padding-left: 5rem;
}

.service-text h4 {
  font-size: 37px;
  color: #fff;
  margin-left: 15px; /* Consistent space between icon and text */
  padding-right: 30px;
  font-weight: 700;
}

.text-white {
  color: white; /* Make sure the text color is consistent */
}

.mb-4 {
  margin-bottom: 20px; /* Consistent bottom margin */
}
body,
html {
  overflow-x: hidden;
}

.container-fluid {
  padding: 0;
}

.row {
  margin: 0;
  width: 100%; /* Ensure full width but without overflow */
}


@media (max-width: 1500.99px) {
  .carousel-caption-heading{
    font-size: 2.3rem !important;
  }}
@media (max-width: 780px) {
  .carousel-caption {
    transform: translate(-120%, -70%) !important;
    width: 100%; 
    
  }}
@media (max-width: 1192.99px) {
  .service-row {
    display: flex !important;
    align-items: center !important; /* Ensures icons and text are aligned vertically */
    justify-content: center !important; /* Aligns the content horizontally */
    padding: 20px 0px 10px 10px !important;
  }}
@media (max-width: 1019.99px) {
  .service-row {
    display: flex !important;
    padding: 20px 0px 10px 20px !important;
  }
  .carousel-caption-heading{
    font-size: 2.3rem !important;
  }
.service-text h4{
  font-size: 1.7rem !important;
}
}
@media (max-width: 868.99px) {
  .service-row {
    display: flex !important;
    padding: 30px 2px 10px 20px !important;
  }
.service-text h4{
  font-size: 1.3rem !important;
}
}
@media (max-width: 780px) {
  .carousel-caption {
    transform: translate(-120%, -70%) !important;
    width: 100%; 
    
  }
  .carousel-caption-heading {
    font-size: 2.2rem !important;
    white-space: nowrap;
  }
  .carousel-caption-text {
    font-size: 1.5rem !important;
    
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: none !important;
  }
}
@media (max-width: 767.99px) {
  .commitment-text-div{
    margin-left: 17% !important;
  }
  .last-section-column{
    align-items: flex-start !important;
    padding-top: 4% !important;
    padding-left: 17% !important;
  }
  .info-section{
    padding-top: 10% !important;
  }
  .read-more-btn3{
    margin-bottom: 12% !important;
  }
  .service-row {
    display: flex !important;
    padding: 30px 2px 10px 0px !important;
  }
.service-text h4{
  font-size: 1.7rem !important;
}
}
@media (max-width: 702.99px) {
  .service-row {
    padding: 30px 2px 10px 0px !important;
  }
.service-text h4{
  font-size: 1.7rem !important;
}
}
@media (max-width: 652px) {
  .carousel-caption {
    transform: translate(-50%, -50%) !important;
    top: 47% !important;
    max-width: 80% !important;
    
  }
  .carousel-item-landing{
    width: 100% !important;
  }
  .carousel-caption-heading {
    font-size: 2.1rem !important;
    white-space: nowrap;
    padding-bottom: 1rem;
  }
  .carousel-caption-text {
    font-size: 1.8rem !important;
    
  }}
@media (max-width: 630.99px) {
  .carousel-caption {
    transform: translate(-50%, -40%) !important;
    top: 47% !important;
    max-width: 80% !important;
    
  }
  .carousel-item-landing{
    width: 100% !important;
  }
  .carousel-caption-heading {
    font-size: 1.8rem !important;
    white-space: nowrap;
  }
  .carousel-caption-text {
    font-size: 1.4rem !important;
    
  }}
@media (max-width: 596px) {
  .carousel-caption {
    width: 100% !important; 
  }
  .carousel-item-landing{
    width: 100% !important;
  }
  .carousel-caption-heading {
    font-size: 2.2rem !important;
    white-space: normal;
  }
  .carousel-caption-text {
    font-size: 1.5rem !important;
    
  }}
@media (max-width: 567.99px) {
 .container-fast-safe{
  margin-left: -60px !important;
 }
 .fast-safe-text{
  font-size: 16px !important;
  font-weight: bold !important;
 }
}
@media (max-width: 567.99px) {
  .service-row {
    padding: 30px 2px 10px 10px !important;
  }
}
@media (max-width: 550.99px) {
  .carousel-caption {
    .carousel-caption-heading{
      font-size: 1.9rem !important;

    }}
    .carousel-caption-text{
      font-size: 1.4rem !important;
    }
    .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: none !important;
  }
}
@media (max-width: 502.99px) {
  .service-row {
    padding: 30px 0px 0px 20px !important;
  }
.service-text h4{
  font-size: 1.3rem !important;
}
}
@media (max-width: 525.99px) {
  .commitment-text-div{
    margin-left: 6% !important;
  }
}

@media (max-width: 475.99px) {
  .container-fast-safe{
   margin-left: -60px !important;
  }
  .fast-safe-heading{
    font-size: 1.8rem !important;
    width: 100% !important;
  }
  .fast-safe-text{
   font-size: 1rem !important;
   font-weight: normal !important;
  }
  .content-area {
    padding-right: 30px !important;
  }
 }

 @media (max-width: 450.99px) {
  .service-row {
    padding: 30px 0px 0px 30px !important;
  }
}
 @media (max-width: 446.99px) {

.service-text h4{
  font-size: 1.1rem !important;
}
}
@media (max-width: 436.99px) {
  .carousel-caption-landing{
    margin-top: -50px !important;
  }
}
 @media (max-width: 400.99px) {
  .service-row {
    padding: 30px 0px 10px 35px !important;
  }
.service-text h4{
  font-size: 1.1rem !important;
}
.icon-box{
  width: 80px !important;
  height: 80px !important;
}
}
 @media (max-width: 360.99px) {

.service-text h4{
  font-size: 0.9rem !important;
}
.icon-box{
  width: 60px !important;
  height: 60px !important;
}
}

@media (max-width: 339.99px) {
  .carousel-caption-landing{
    margin-top: -80px !important;
  }
  .service-row {
    padding: 30px 0px 10px 45px !important;
  }

}