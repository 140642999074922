/* Global Styles */
body,
html {
  overflow-x: hidden;
}

/* Section Styling */
.contact {
  padding: 2rem 0;
}

.contact-first-column {
  padding: 2rem;
}

.contact-info {
  max-width: 80%;
  margin-left: auto; /* Pushes the content to the right */
  text-align: center;
}

.contact-heading {
  font-weight: 500;
  font-size: 4.2rem;
  color: #242426;
}

.contact-address,
.contact-phone,
.contact-email {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

/* Contact Form */
.contact-card {
  background-color: #d3d3d3;
  border: none;
  width: 100%;
  max-width: 500px;
  border-radius: 0;
}

.contact-form {
  margin-top: 2rem;
}

.contact-form input,
.contact-form textarea {
  border-radius: 0;
  margin-bottom: 1rem;
}

.contact-submit-button {
  padding: 0.5rem 2rem;
  font-size: 1rem;
  border: none;
  background-color: #fff;
  border: 2px solid #B22222; /* Updated color */
  color: #B22222;
}

.contact-submit-button:hover {
  background-color: #B22222;
  color: #fff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .contact-heading {
    font-size: 2.5rem;
  }

  .contact-address,
  .contact-phone,
  .contact-email {
    font-size: 0.9rem;
  }

  .contact-card {
    margin-top: 2rem;
  }

  .contact-form {
    padding: 0 1rem;
  }
}

@media (max-width: 576px) {
  .contact-heading {
    font-size: 2rem;
  }

  .contact-card {
    max-width: 100%;
    padding: 1rem;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 0.8rem;
  }

  .contact-submit-button {
    width: 100%;
  }
}
.text-right{
  margin-bottom: 3rem;
}