/* Card Styling */
.product-card {
  width: 90%; /* Adjust the card width */
  background: transparent;
  border: none;
  margin-bottom: 100px;
  height: 400px;
  margin-top: 10px !important;
}

.product-image {
  height: 310px; /* Adjust based on your design */
  object-fit: contain; /* Keeps the image aspect ratio */
}

.product-title {
  font-size: 1.3rem;
  font-weight: 400;
  color: #333;
  margin-top: 10px;
  text-align: center;
}

.product-brand {
  font-size: 0.9rem;
  color: #888;
  text-align: center;
}

.card-body {
  text-align: center; /* Center text */
}

@media (max-width: 1281.99px) {
  .product-card {
    margin-bottom: -20% !important;
  }
  .product-image{
    height: 120px !important;
    width: 120px !important;
  }
}
@media (max-width: 770.99px) {
  .product-card {
    height: 350px !important;
    align-items: center !important;
  }
  .hide-on-small-screens{
    display: none !important;
  }
  .product-image{
    height: 150px !important;
  }

}
@media (max-width: 768px) {
  .product-card {
    height: 350px !important;
    align-items: center !important;
  }
  .product-image{
    height: 150px !important;
  }
  /* .product-title{
    margin-right: 12rem !important;
  }
  .product-brand{
    margin-right: 12rem !important;
  } */
}

@media (max-width: 576px) {
  .product-image {
    height: 150px;
  }
}
