.service-row {
}

.service-text h4 {
  /* font-size: 37px; */
  /* color: #fff; */
  /* margin-left: 15px; Consistent space between icon and text */
  /* padding-right: 30px; */
  /* font-weight: 700; */
}


.icon-box {
  width: 120px !important; /* Set a fixed size for the icon container */
  height: 76px;
  background-color: #B22222; /* Red background color */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  margin-left: -5rem;
}

@media (max-width: 767.99px) {
  .values-section{
    padding-left: 15%;
    padding-bottom: 10%;
  }
  /* .prices-section{
    padding-top: 4% !important;
  } */
  .service-row4{
    margin-left: 12% !important;
  }
  .icon-box{
    width: 70px !important;
    height: 70px !important;
    margin-left: -5rem !important;
  }
  .values-section h1{
    font-size: 30px !important;
  }
  .service-text{
    font-size: 1rem !important;
    padding-right: 1rem !important;
  }
  .prices-section{
    margin-top: 15% !important;
  }

}
@media (max-width: 703.99px) {
 
  .prices-section{
    margin-top: 20% !important;
  }
  .service-title-h4{
    margin-left: 3.7rem !important;
  }
}
@media (max-width: 450.99px) {

  .prices-section{
    margin-top: 20% !important;
  }
  .service-title-h4{
    margin-left: 3.7rem !important;
  }
  .strategy-header{
    font-size: 2rem !important;
    margin-top: 5%;
    margin-bottom: -5%;
  }
}
@media (max-width: 410.99px) {

  .prices-section{
    margin-top: 20% !important;
  }
  .service-title-h4{
    margin-left: 3.7rem !important;
    font-size: 1rem !important;
  }
  .strategy-header{
    font-size: 1.6rem !important;
    margin-top: 5%;
    margin-bottom: -5%;
  }
  .service-description{
    font-size: 0.8rem !important;
  }
}

/* 
@media (max-width: 767.99px) {
  .values-section{
    padding-top: 10% !important;
    padding-left: 2% !important;
  }
  .prices-section{
    padding-top: 4% !important;
  }

} */

@media (max-width: 640.99px) {
  .contact-info{
    transform: translateX(20%) !important;
}}
@media (max-width: 576px) {
  .contact-info{
    transform: translateX(-60%) !important;
}
@media (max-width: 540px) {
  .contact-info{
    transform: translateX(-50%) !important;
}}
@media (max-width: 491px) {
  .contact-info{
    transform: translateX(-40%) !important;
}}

}
@media (max-width: 467.99px) {
  .contact-info{
    transform: translateX(-35%) !important;
}
}
@media (max-width: 413.99px) {
  .contact-info{
    transform: translateX(-30%) !important;
}
}
@media (max-width: 410.99px) {
  .contact-info{
    transform: translateX(-25%) !important;
}
}
@media (max-width: 390.99px) {
  .contact-info{
    transform: translateX(-20%) !important;
}
}
@media (max-width: 370.99px) {
  .contact-info{
    transform: translateX(-15%) !important;
}
}
/* .icon-img{
  width: 60px;
  height: auto;
} */